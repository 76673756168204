import axios from 'axios';
import { navigate } from 'gatsby';
import { stringify, parse, stringifyUrl } from 'query-string';
import Cookies from 'js-cookie';
import { twMerge } from 'tailwind-merge';
import classNames from 'classnames';
import { getAppContextValue } from '@/components/AppProvider/AppProviderContext';
import { getApp } from '.';

const regExp = {
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};

export const validEmail = (val) => regExp.email.test(val);

export const capitalizeFirstLetter = (string) => string && string.charAt(0).toUpperCase() + string.slice(1);

export const toVerify = ({ app, location, email }) => {
  const { ec, ee, eu, app: appFromLocation, ...query } = parse(location.search);
  const { shopify } = getApp(location);

  navigate(
    stringifyUrl({
      url: shopify.country === 'us' ? '/email-verify' : `/${shopify.country}/email-verify`,
      query: {
        ...(query || {}),
        redirect: location.pathname,
        app,
        email,
      },
    }),
  );
};

// 订阅专用，走 subscribe
export const isEuropeEmail = async (param) => {
  try {
    const res = await axios({ url: `/subscribe/marketo/brand?${stringify(param)}` });
    return res.data.from_europe;
  } catch (e) {
    return null;
  }
};

// 订阅专用，走 subscribe
export const decodeEmail = async (param) => {
  try {
    const res = await axios({ url: `/subscribe/decode_e?${stringify(param)}` });
    return res.data.email;
  } catch (e) {
    return null;
  }
};

export const getSubs = async (param) =>
  axios({
    url: `/subscribe/subscriptions?${stringify(param)}`,
  });

export const putSubsWithReasons = (data) =>
  axios({
    url: '/subscribe/subscribes2', // 退订页面不存在创建，都使用 put 更新
    method: 'put',
    data,
  });

export const putSubs = async (data) =>
  axios({
    url: '/subscribe/subscriptions',
    method: 'put',
    data,
  });

// 订阅专用，走 subscribe
export const sendCode = (headers, data) =>
  axios({
    url: '/subscribe/email_auth_tokens/send_code',
    method: 'post',
    headers,
    data,
  });

// 订阅专用，走 subscribe
export const checkCode = (data) =>
  axios({
    url: '/subscribe/email_auth_tokens/check_code',
    method: 'post',
    data,
  });

export const fetchCountries = (param) =>
  axios({
    url: `/identity/countries?${stringify(param)}`,
  });

export const fetchProfile = (param, headers) =>
  axios({
    url: `/identity/users/profile?${stringify(param)}&_=${Date.now()}`,
    headers,
  });

export const putProfile = (headers, data) =>
  axios({
    url: '/identity/users/update_profile',
    method: 'put',
    headers,
    data,
  });

export const postPrivacyRequest = (headers, data) =>
  axios({
    url: '/.netlify/functions/privacy-request',
    method: 'post',
    headers,
    data,
  });

export const getAvatarUrl = (headers, data) =>
  axios({
    url: '/avatar',
    method: 'post',
    headers,
    data,
  });

export const saveAvatar2aws = (url, body) =>
  fetch(url, {
    method: 'PUT',
    body,
  });

export function currencySymbol(name) {
  const CURRENCY_CODE = {
    USD: '$',
    EUR: '€',
    GBP: '£',
    CAD: '$',
  };

  return CURRENCY_CODE[name] || ' ';
}

export function getPath(path, query = {}) {
  const [pathWithoutHash, hash] = path.split('#');
  const [pathname, pathQueryString = ''] = pathWithoutHash.split('?');

  let navigatePath = pathname;

  const queryObj = {
    ...parse(pathQueryString),
    ...query,
  };

  // 每次往 passport.xxx.com 域名跳转、相对地址路径跳转的url都需要携带app参数
  // start_ai_generated
  if (!pathname.includes('passport') || !pathname.startsWith('//') || !pathname.startsWith('http')) {
    const { app } = getApp();
    queryObj.app = app;
  }
  // end_ai_generated

  const queryString = stringify(queryObj);

  navigatePath += queryString ? `?${queryString}` : '';

  if (hash) {
    navigatePath += `#${hash}`;
  }

  return navigatePath;
}

export function navigateTo(path, query = {}, options) {
  // 安全路径判断
  if (!path.startsWith('/') && !path.startsWith('//') && !path.startsWith('http')) {
    return Promise.resolve();
  }

  if (path.startsWith('//') || path.startsWith('http')) {
    window.location.href = path;
    return Promise.resolve();
  }

  const navigatePath = getPath(path, query);
  return navigate(navigatePath, options);
}

export function replacePathOnly(path, query = {}) {
  const replacePath = getPath(path, query);
  window.history.replaceState({}, null, replacePath);
}

export function scrollToByHash(hash) {
  const navigatePath = `${window.location.search}#${hash}`;
  navigate(navigatePath);
}

export function isIframe() {
  return window.location !== window.parent.location;
}

let userInfoCache;
export function removeUserInfo(app) {
  // 清除 .{brand}.com 下非 http-only 的 cookie
  const cookieName = `${app}-token`;
  const tokenCookie = Cookies.get(cookieName);
  if (tokenCookie) {
    const { brand } = getAppContextValue();
    Cookies.remove(cookieName, {
      domain: `.${brand === 'nebula' ? 'seenebula' : brand}.com`,
      expires: 'Thu, 01 Jan 1970 00:00:00 GMT',
      path: '/',
    });
  }

  userInfoCache = null;
}

export function saveUserInfo(_, userInfo) {
  userInfoCache = userInfo;
}

export function getUserInfo() {
  return userInfoCache;
}

export function pageGTMEvent(data) {
  if (typeof window === 'undefined') {
    return;
  }

  function pushData(eventData) {
    window.dataLayer = window?.dataLayer || [];
    window.dataLayer.push({ event_parameters: null });
    window.dataLayer.push(eventData);
  }

  if (window.google_tag_manager) {
    // gtm.js 已经加载完成
    pushData(data);
  } else {
    // gtm.js 未加载完成
    window.addEventListener('gtmLoaded', () => {
      pushData(data);
    });
  }
}

export function dataCollect(data) {
  window.dataLayer = window.dataLayer || [];
  pageGTMEvent({
    event: 'ga4Event',
    event_name: 'user_center',
    event_parameters: {
      ...data,
    },
  });
}

export function encodeOrderName(orderName) {
  return orderName.replace(/#/g, 'hash_tag');
}

export function decodeOrderName(orderName) {
  return orderName.replace(/hash_tag/g, '#');
}

export function uploadCommerceEvents(data, locale, headers) {
  const { extraData, sub, menu, type, token, source, ...otherData } = data;
  const userAgent = window && window.navigator.userAgent;
  const postdata = {
    events: [
      {
        ab_code: locale.toUpperCase(),
        business: 'Referral',
        event_local_time: new Date(),
        event_time: Date.parse(new Date()),
        event_type: {
          page: 10001,
          click: 10100,
        }[data?.type], // 进入页面(或回到页面) 10002-页面加载完成 10003-退出页面 10100-点击事件
        extra1: data?.extraData, // 保留字段1
        extra2: `{"userAgent": "${userAgent}"}`, // 保留字段2
        log_version: 100, // 日志版本号，100-表示1.00版本
        module: data?.menu, // 所属模块，如: ShopHomePage
        sub_module: data?.sub, // 所属子模块，如: ReferalInviteeHomePage
        ...(Object.keys(otherData).length && {
          click_data: { ...otherData },
        }), // 点击数据
        session_id: Cookies.get('_ga'), // 会话ID(UserID后4位_时间戳)，如: f22a_1583251646199
        source, // 来源，如: H5(来自H5) App(来自App) Web(来自Web端),
        user_type: 10000, // 用户类型，0-未定义 1-没有任何产品 2-被分享用户 3-owner用户 4-owner+被分享用户
      },
    ],
  };
  let urlEnv = '';
  if (process.env.ENVIRONMENT === 'production') {
    urlEnv = ['DE', 'UK', 'EU', 'NL'].includes(locale.toUpperCase()) ? 'security-log-eu-func' : 'security-log-func';
  } else {
    urlEnv = 'security-log-qa-func';
  }
  return fetch(`/${urlEnv}/v1/app/logging/upload_commerce_events`, {
    method: 'POST',
    body: JSON.stringify(postdata),
    headers: { ...headers, 'current-language': locale },
  });
}

export function scrollParentToChild(parent, child, scrollToTop = true) {
  // Where is the parent on page
  const parentRect = parent.getBoundingClientRect();
  // What can you see?
  const parentViewableArea = {
    height: parent.clientHeight,
    width: parent.clientWidth,
  };

  // Where is the child
  const childRect = child.getBoundingClientRect();
  // Is the child viewable?
  const isViewable = childRect.top >= parentRect.top && childRect.bottom <= parentRect.top + parentViewableArea.height;

  // if you can't see the child try to scroll parent
  if (!isViewable) {
    // Should we scroll using top or bottom? Find the smaller ABS adjustment
    const scrollTop = childRect.top - parentRect.top;
    const scrollBottom = childRect.bottom - parentRect.bottom;
    if (scrollToTop) {
      // eslint-disable-next-line no-param-reassign
      parent.scrollTop += scrollTop;
    } else {
      // eslint-disable-next-line no-param-reassign
      parent.scrollTop += scrollBottom;
    }
  }
}

/**
 * Filters an array of objects based on a unique key.
 *
 * @param array - The array of objects to filter.
 * @param key - The key to use for comparison.
 * @returns An array of objects with unique values for the specified key.
 */
export function filterSameItemByKey(array, key) {
  return array.filter((value, index, self) => index === self.findIndex((t) => t[key] === value[key]));
}

export const cn = (...props) => twMerge(classNames(props));

// creditTask 数据结构上线更替兼容
export function adaptCreditTask(creditTask) {
  if (creditTask?.tasks) {
    return creditTask;
  } else {
    return {
      title: '',
      tasks: creditTask,
    };
  }
}

export function escapeHTML(str) {
  const map = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#039;',
    '/': '&#x2F;',
    '\\': '&#x5C;',
  };

  if (str) {
    return str.replace(/[&<>"']/g, function (m) {
      return map[m];
    });
  } else {
    return '';
  }
}
